<template>
  <Dialog :visible="isVisible" :modal="true" @update:visible="onHideDialogs" class="p-fluid">
    <template #header>
      <h4>{{ item.id ? 'Modifier' : 'Créer' }} un tarif</h4>
    </template>
    <p>
      <b
        ><u>NB:</u> Les champs dont le libellé se termine par <span class="p-text-bold text-red">*</span> sont
        obligatoires.</b
      >
    </p>
    <div class="p-field p-col-12">
      <label for="adherant-id">Adherant <span class="p-text-bold text-red">*</span></label>
      <InputText
        v-model="v$.tarif.adherant.$model"
        :class="{ 'p-invalid': v$.tarif.adherant.$invalid && isSubmitted }"
        id="name-id"
        type="text"
      />
      <small v-if="v$.tarif.adherant.$invalid && isSubmitted" class="p-error"> L'adherant est obligatoire. </small>
    </div>
    <div class="p-field p-col-12">
      <label for="rang-id">Rang<span class="p-text-bold text-red">*</span></label>
      <Dropdown
        id="rang-id"
        v-model="v$.tarif.rang.$model"
        :class="{ 'p-invalid': v$.tarif.rang.$invalid && isSubmitted }"
        :options="rangList"
        optionLabel="name"
        optionValue="id"
        placeholder="Choisir"
        :filter="true"
      />
      <small v-if="v$.tarif.rang.$invalid && isSubmitted" class="p-error"> Le rang est obligatoire. </small>
    </div>
    <div class="p-field p-col-12">
      <label for="montant_xof-id">Montant (CFA) <span class="p-text-bold text-red">*</span></label>
      <InputNumber
        v-model="v$.tarif.montant_xof.$model"
        :class="{ 'p-invalid': v$.tarif.montant_xof.$invalid && isSubmitted }"
        id="montant_xof-id"
        type="text"
      />
      <small v-if="v$.tarif.montant_xof.$invalid && isSubmitted" class="p-error">
        Le montant (CFA) est obligatoire.
      </small>
    </div>
    <!--<div class="p-field p-col-12">
      <label for="montant_dollar_us-id">Montant (DOLLAR US) <span class="p-text-bold text-red">*</span></label>
      <InputNumber v-model="v$.tarif.montant_dollar_us.$model" :class="{ 'p-invalid': v$.tarif.montant_dollar_us.$invalid && isSubmitted }" id="montant_dollar_us-id" type="text" />
      <small v-if="v$.tarif.montant_dollar_us.$invalid && isSubmitted" class="p-error"> Le montant (DOLLAR US) est obligatoire. </small>
    </div>-->

    <template #footer>
      <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialogs" />
      <Button
        label="Enregistrer"
        class="p-button p-component p-button-outlined p-button-success"
        :loading="isSubmitting === 'SUBMIT_REQUESTED'"
        @click="onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script>
import { required } from '@vee-validate/rules';
import { useVuelidate } from '@vuelidate/core';

export default {
  setup: () => ({ v$: useVuelidate() }),

  mounted() {
    if (this.item) {
      this.tarif = {
        id: this.item.id,
        adherant: this.item.adherant,
        rang: this.item.rang,
        montant_xof: this.item.montant_xof,
        // montant_dollar_us: value.montant_dollar_us,
      };
    }
  },

  data: () => ({
    isSubmitted: false,
    tarif: {
      adherant: null,
      rang: null,
      montant_xof: null,
      // montant_dollar_us: null,
    },
    rangList: [
      { name: 'Major', id: 'MAJOR' },
      { name: 'Prestataire de rang un (1)', id: 'RANK_UN' },
      { name: 'Prestataire de rang un (2)', id: 'RANK_DEUX' },
      { name: 'Entreprise étrangère', id: 'ETRANGER' },
      { name: 'Entreprise de droit Sénégalais', id: 'DROIT_SENEGALAIS' },
      { name: 'Entreprise local', id: 'LOCAL' },
    ],
  }),

  props: {
    item: Object,
    isVisible: Boolean,
    isSubmitting: String,
    extras: Object,
  },

  validations() {
    return {
      tarif: {
        adherant: {
          required,
        },
        rang: {
          required,
        },
        montant_xof: {
          required,
        },
        /*montant_dollar_us: {
          required,
        },*/
      },
    };
  },

  methods: {
    onHideDialogs() {
      this.$emit('closed');
    },
    onSubmit() {
      this.isSubmitted = true;
      if (this.v$.tarif.$invalid === false) this.$emit('submitted', this.tarif);
    },
  },

  watch: {
    isSubmitting: function (value) {
      if (value === 'SUBMIT_SUCCEED') {
        this.tarif = {
          adherant: null,
          rang: null,
          montant_xof: null,
          // montant_dollar_us: null,
        };
        this.isSubmitted = false;
      }
    },
    item: function (value) {
      if (undefined !== value.id) {
        this.tarif = {
          id: value.id,
          adherant: value.adherant,
          rang: value.rang,
          montant_xof: value.montant_xof,
          // montant_dollar_us: value.montant_dollar_us,
        };
      } else {
        this.tarif = {
          adherant: null,
          rang: null,
          montant_xof: null,
          // montant_dollar_us: null,
        };
      }
    },
  },
};
</script>

<style scoped>
.text-red {
  color: #db3c30;
}
</style>