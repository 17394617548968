<template>
  <Dialog :visible="isVisible" :style="{ width: '600px' }" header="Êtes-vous sûr de vouloir effectuer cette opération ?" :modal="true" class="p-fluid" :closable="false">
    <template #footer>
      <Button label="NON" icon="pi pi-delete" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialog" />
      <Button label="OUI" icon="pi pi-delete" class="p-button p-component p-button-outlined p-button-success" @click="onDelete" />
    </template>
  </Dialog>
</template>

<script>
export default {
  data: () => {
    return {
      isHidden: false,
    };
  },
  props: {
    isVisible: Boolean,
    item: Object,
  },
  methods: {
    onDelete() {
      this.$emit('deleted', this.item?.id);
    },

    onHideDialog() {
      this.$emit('closed');
    },
  },
};
</script>