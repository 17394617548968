<template>
  <DataTable
    ref="categorie-list"
    data-key="id"
    :value="items"
    :paginator="true"
    :rows="10"
    :rowsPerPageOptions="[5, 10, 25, 100]"
    currentPageReportTemplate="Page {first}, {totalRecords} categories."
  >
    <template #header>
      <div
        class="
          table-header
          p-d-flex
          p-flex-column
          p-flex-md-row
          p-jc-md-between
          p-ai-start
          p-ai-md-center
        "
      >
        <h5 class="p-mb-2 p-m-md-0">Gestion des domaines métiers</h5>
        <Button
          label="Ajouter un domaine metier"
          class="p-mr-2 p-mb-2"
          @click="onCreate"
        />
      </div>
    </template>

    <Column
      field="libelle"
      header="Libellé"
      :sortable="true"
      bodyStyle="text-indent:1em"
    >
      <template #body="slotProps">
        <span class="p-column-title">Libellé</span>
        {{ slotProps.data.libelle }}
      </template>
    </Column>
    <Column
      field="libelle_en"
      header="Libellé (EN)"
      :sortable="true"
      bodyStyle="text-indent:1em"
    >
      <template #body="slotProps">
        <span class="p-column-title">Libellé (EN)</span>
        {{ slotProps.data.libelle_en }}
      </template>
    </Column>
    <Column
      field="code"
      header="Code"
      :sortable="true"
      bodyStyle="text-indent:1em"
    >
      <template #body="slotProps">
        <span class="p-column-title">Code</span>
        {{ slotProps.data.code }}
      </template>
    </Column>
    <Column
      field="regimeCinqAns"
      header="Régime"
      :sortable="true"
      bodyStyle="text-indent:1em"
    >
      <template #body="slotProps">
        <span class="p-column-title">Code</span>
        {{ slotProps.data.regimeCinqAns }}
      </template>
    </Column>
    <Column
      field="categorie.name"
      header="Categorie"
      :sortable="true"
      bodyStyle="text-indent:1em"
    >
      <template #body="slotProps">
        <span class="p-column-title">Code</span>
        {{ slotProps.data.categorie.name }}
      </template>
    </Column>

    <Column
      field="action"
      header="Actions"
      :sortable="true"
      bodyStyle="text-indent:1em"
    >
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        <Button
          class="p-button-outlined p-button-rounded p-button-info p-mr-2"
          @click="onEdit(slotProps.data)"
          v-tooltip.top="'Modifier'"
          icon="pi pi-pencil"
        />
        <Button
          class="p-button-outlined p-button-rounded p-button-danger p-mr-2"
          @click="onDelete(slotProps.data)"
          v-tooltip.top="'Supprimer'"
          icon="pi pi-trash"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
export default {
  props: {
    items: Array,
  },

  methods: {
    onCreate() {
      this.$emit('created');
    },

    onEdit(item) {
      this.$emit('edited', item);
    },

    onDelete(item) {
      this.$emit('deleted', item);
    },
  },
};
</script>