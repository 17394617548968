import store from '../../store'

export function codeValidatorWrapper(namespace, itemCode) {
  function codeValidator(value) {
    const isValueNull = itemCode === null;
    const entities = store.getters[`${namespace}/getAllEntities`];
    const codeMap = entities.reduce((h, entity) => ({ ...h, [entity.code]: entity }), ({}));
    const isFieldValid = isValueNull ? codeMap[value] === undefined : (codeMap[value] === undefined || itemCode.code === value);
    return isFieldValid; // false
  }
  return codeValidator;
}