<template>
  <Dialog
    :visible="isVisible"
    :modal="true"
    @update:visible="onHideDialogs"
    class="p-fluid"
  >
    <template #header>
      <h4>{{ item.id ? 'Modifier' : 'Créer' }} une nature de prestation</h4>
    </template>
    <p>
      <b
        ><u>NB:</u> Les champs dont le libellé se termine par
        <span class="p-text-bold text-red">*</span> sont obligatoires.</b
      >
    </p>

    <div class="p-field p-col-12">
      <label for="libelle-id"
        >Libellé <span class="p-text-bold text-red">*</span></label
      >
      <InputText
        v-model="v$.naturePrestation.libelle.$model"
        :class="{
          'p-invalid': v$.naturePrestation.libelle.$invalid && isSubmitted,
        }"
        id="libelle-id"
        type="text"
      />
      <small
        v-if="v$.naturePrestation.libelle.$invalid && isSubmitted"
        class="p-error"
      >
        Le libellé est obligatoire.
      </small>
    </div>

    <div class="p-field p-col-12">
      <label for="code-id"
        >Code <span class="p-text-bold text-red">*</span></label
      >
      <InputText
        v-model="v$.naturePrestation.code.$model"
        :class="{
          'p-invalid': v$.naturePrestation.code.$invalid && isSubmitted,
        }"
        id="code-id"
        type="text"
      />
      <small
        v-if="v$.naturePrestation.code.$invalid && isSubmitted"
        class="p-error"
      >
        Le code est obligatoire et doit être unique.
      </small>
    </div>

    <template #footer>
      <Button
        label="Annuler"
        class="p-button p-component p-button-outlined p-button-danger"
        @click="onHideDialogs"
      />
      <Button
        label="Enregistrer"
        class="p-button p-component p-button-outlined p-button-success"
        :loading="isSubmitting === 'SUBMIT_REQUESTED'"
        @click="onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script>
import { required } from '@vee-validate/rules';
import { useVuelidate } from '@vuelidate/core';
import { codeValidatorWrapper } from '../utils';

export default {
  setup: () => ({ v$: useVuelidate() }),

  mounted() {
    this.resetItem(this.item);
  },

  data: () => ({
    isSubmitted: false,
    naturePrestation: {
      libelle: null,
      code: null,
    },
  }),

  props: {
    item: Object,
    isVisible: Boolean,
    isSubmitting: String,
    extras: Object,
  },

  validations() {
    return {
      naturePrestation: {
        libelle: {
          required,
        },
        code: {
          required,
          codeValidatorWrapper: codeValidatorWrapper(
            'naturePrestation',
            this.item
          ),
        },
      },
    };
  },

  methods: {
    onHideDialogs() {
      this.$emit('closed');
    },
    onSubmit() {
      this.isSubmitted = true;
      if (this.v$.naturePrestation.$invalid === false)
        this.$emit('submitted', this.naturePrestation);
    },
    resetItem(value) {
      if (undefined !== value.id) {
        this.naturePrestation = {
          id: value.id,
          code: value.code,
          libelle: value.libelle,
        };
      } else {
        this.naturePrestation = {
          libelle: null,
          code: null,
        };
      }
    },
  },

  watch: {
    isSubmitting: function (value) {
      if (value === 'SUBMIT_SUCCEED') {
        this.naturePrestation = {
          libelle: null,
          code: null,
        };
        this.isSubmitted = false;
      }
    },
    item: function (value) {
      if (undefined !== value.id) {
        this.naturePrestation = {
          id: value.id,
          code: value.code,
          libelle: value.libelle,
        };
      } else {
        this.naturePrestation = {
          libelle: null,
          code: null,
        };
      }
    },
  },
};
</script>

<style scoped>
.text-red {
  color: #db3c30;
}
</style>