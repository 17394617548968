<template>
  <Dialog
    :visible="isVisible"
    :modal="true"
    @update:visible="onHideDialogs"
    class="p-fluid"
  >
    <template #header>
      <h4>{{ item.id ? 'Modifier' : 'Créer' }} un domaine métier</h4>
    </template>
    <p>
      <b
        ><u>NB:</u> Les champs dont le libellé se termine par
        <span class="p-text-bold text-red">*</span> sont obligatoires.</b
      >
    </p>

    <div class="p-field p-col-12">
      <label for="libelle-id"
        >Libellé <span class="p-text-bold text-red">*</span></label
      >
      <InputText
        v-model="v$.domaineMetier.libelle.$model"
        :class="{
          'p-invalid': v$.domaineMetier.libelle.$invalid && isSubmitted,
        }"
        id="libelle-id"
        type="text"
      />
      <small
        v-if="v$.domaineMetier.libelle.$invalid && isSubmitted"
        class="p-error"
      >
        Le libellé est obligatoire.
      </small>
    </div>

    <div class="p-field p-col-12">
      <label for="libelle-id"
        >Code <span class="p-text-bold text-red">*</span></label
      >
      <InputText
        v-model="v$.domaineMetier.code.$model"
        :class="{ 'p-invalid': v$.domaineMetier.code.$invalid && isSubmitted }"
        id="code-id"
        type="text"
      />
      <small
        v-if="v$.domaineMetier.code.$invalid && isSubmitted"
        class="p-error"
      >
        Le code est obligatoire et doit être unique.
      </small>
    </div>

    <div class="p-field p-col-12">
      <label for="libelle-en-id">Libellé (EN)</label>
      <InputText
        v-model="v$.domaineMetier.libelle_en.$model"
        :class="{
          'p-invalid': v$.domaineMetier.libelle_en.$invalid && isSubmitted,
        }"
        id="libelle-en-id"
        type="text"
      />
    </div>

    <div class="p-field p-col-12">
      <label for="regime-id"
        >Régime <span class="p-text-bold text-red">*</span></label
      >
      <Dropdown
        id="regime-id"
        v-model="v$.domaineMetier.regime.$model"
        :class="{
          'p-invalid': v$.domaineMetier.regime.$invalid && isSubmitted,
        }"
        :options="typeRegimes"
        placeholder="Choisir"
      />
      <small
        v-if="v$.domaineMetier.regime.$invalid && isSubmitted"
        class="p-error"
      >
        Le type de régime est obligatoire.
      </small>
    </div>

    <div class="p-field p-col-12">
      <label for="regime-cinq-ans-id"
        >Régime dans cinq (5) ans<span class="p-text-bold text-red"
          >*</span
        ></label
      >
      <Dropdown
        id="regime-cinq-ans-id"
        v-model="v$.domaineMetier.regimeCinqAns.$model"
        :class="{
          'p-invalid': v$.domaineMetier.regimeCinqAns.$invalid && isSubmitted,
        }"
        :options="typeRegimes"
        placeholder="Choisir"
      />
      <small
        v-if="v$.domaineMetier.regimeCinqAns.$invalid && isSubmitted"
        class="p-error"
      >
        Le type de régime dans les 5 ans à venir est obligatoire.
      </small>
    </div>

    <div class="p-field p-col-12">
      <label for="caegories-id"
        >Catégorie<span class="p-text-bold text-red">*</span></label
      >
      <Dropdown
        id="caegories-id"
        v-model="v$.domaineMetier.categoryId.$model"
        :class="{
          'p-invalid': v$.domaineMetier.categoryId.$invalid && isSubmitted,
        }"
        :options="extras?.categories || []"
        optionLabel="name"
        optionValue="id"
        placeholder="Choisir"
        :filter="true"
      />
      <small
        v-if="v$.domaineMetier.categoryId.$invalid && isSubmitted"
        class="p-error"
      >
        La catégorie est obligatoire.
      </small>
    </div>

    <template #footer>
      <Button
        label="Annuler"
        class="p-button p-component p-button-outlined p-button-danger"
        @click="onHideDialogs"
      />
      <Button
        label="Enregistrer"
        class="p-button p-component p-button-outlined p-button-success"
        :loading="isSubmitting === 'SUBMIT_REQUESTED'"
        @click="onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script>
import { required } from '@vee-validate/rules';
import { useVuelidate } from '@vuelidate/core';
import { codeValidatorWrapper } from '../utils';

export default {
  setup: () => ({ v$: useVuelidate() }),

  mounted() {
    this.resetItem(this.item);
  },

  data: () => ({
    isSubmitted: false,
    domaineMetier: {
      libelle: null,
      libelle_en: null,
      code: null,
      regime: null,
      regimeCinqAns: null,
      parentId: null,
      categoryId: null,
    },
    typeRegimes: ['EXCLUSIF', 'MIXTE', 'NON_EXCLUSIF'],
  }),

  props: {
    item: Object,
    isVisible: Boolean,
    isSubmitting: String,
    extras: Object,
  },

  validations() {
    return {
      domaineMetier: {
        libelle: {
          required,
        },
        code: {
          required,
          codeValidatorWrapper: codeValidatorWrapper(
            'domainesMetiers',
            this.item
          ),
        },
        libelle_en: {},
        regime: {
          required,
        },
        regimeCinqAns: {
          required,
        },
        parentId: {},
        categoryId: {
          required,
        },
      },
    };
  },

  methods: {
    onHideDialogs() {
      this.$emit('closed');
    },
    onSubmit() {
      this.isSubmitted = true;
      if (this.v$.domaineMetier.$invalid === false)
        this.$emit('submitted', this.domaineMetier);
    },
    resetItem(value) {
      if (undefined !== value.id) {
        this.domaineMetier = {
          id: value.id,
          code: value.code,
          libelle: value.libelle,
          libelle_en: value.libelle_en,
          regime: value.regime,
          regimeCinqAns: value.regimeCinqAns,
          parentId: value.parent && value.parent.id,
          categoryId: value.categorie.id,
        };
      } else {
        this.domaineMetier = {
          libelle: null,
          libelle_en: null,
          code: null,
          regime: null,
          regimeCinqAns: null,
          parentId: null,
          categoryId: null,
        };
      }
    },
  },

  watch: {
    isSubmitting: function (value) {
      if (value === 'SUBMIT_SUCCEED') {
        this.domaineMetier = {
          libelle: null,
          code: null,
          libelle_en: null,
          regime: null,
          regimeCinqAns: null,
          parentId: null,
          categoryId: null,
        };
        this.isSubmitted = false;
      }
    },
    item: function (value) {
      this.resetItem(value);
    },
  },
};
</script>

<style scoped>
.text-red {
  color: #db3c30;
}
</style>