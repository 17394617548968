<template>
  <DataTable ref="categorie-list" data-key="id" :value="items" :paginator="true" :rows="10" :rowsPerPageOptions="[5, 10, 25, 100]" currentPageReportTemplate="Page {first}, {totalRecords} categories.">
    <template #header>
      <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
        <h5 class="p-mb-2 p-m-md-0">Gestion des catégories</h5>
        <Button label="Ajouter une catégorie" class="p-mr-2 p-mb-2" @click="onCreate" />
      </div>
    </template>

    <Column field="id" header="Id" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">#</span>
        {{ slotProps.data.id }}
      </template>
    </Column>
    <Column field="name" header="Intitulé" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Libellé</span>
        {{ slotProps.data.name }}
      </template>
    </Column>
    <Column field="name" header="Intitulé" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Libellé (EN)</span>
        {{ slotProps.data.libelle_en }}
      </template>
    </Column>
    <Column field="code" header="Code" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Intitulé</span>
        {{ slotProps.data.code }}
      </template>
    </Column>
    <Column field="action" header="Actions" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        <Button class="p-button-outlined p-button-rounded p-button-info p-mr-2" @click="onEdit(slotProps.data)" v-tooltip.top="'Modifier'" icon="pi pi-pencil" />
        <Button class="p-button-outlined p-button-rounded p-button-danger p-mr-2" @click="onDelete(slotProps.data)" v-tooltip.top="'Supprimer'" icon="pi pi-trash" />
      </template>
    </Column>
  </DataTable>
</template>

<script>
export default {
  props: {
    items: Array,
  },

  methods: {
    onCreate() {
      this.$emit('created');
    },

    onEdit(category) {
      this.$emit('edited', category);
    },

    onDelete(category) {
      this.$emit('deleted', category);
    },
  },
};
</script>