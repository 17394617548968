<template>
  <div class="p-grid crud-demo">
    <Toast position="top-right" />
    <div class="p-col-12">
      <div class="card">
        <component
          v-if="listComponent"
          :is="listComponent"
          :items="items"
          @created="handleEditDialog"
          @edited="handleEditDialog"
          @deleted="handleDelete"
        />
      </div>
    </div>

    <component
      v-if="formComponent && formIsVisible"
      :is="formComponent"
      :isVisible="formIsVisible"
      :extras="extras"
      :isSubmitting="isSubmitting"
      :item="item"
      @submitted="handleEdit"
      @closed="hideDialogs"
    />
    <component
      v-if="confirmDeletionIsVisible"
      :is="'confirm-delete-item'"
      :item="item"
      :isVisible="confirmDeletionIsVisible"
      @closed="hideDialogs"
      @deleted="handleConfirmDeletion"
    />
  </div>
</template>

<script>
import {
  SUBMIT_FAILED,
  SUBMIT_REQUESTED,
  SUBMIT_SUCCEED,
} from '@/mixins/app-conts';

import ConfirmDeleteItem from './general-page/confirm-delete-item.vue';
import CategorieList from './general-page/categorie-list.vue';
import FormItem from './general-page/form.item.vue';
import DomaineMetierList from './general-page/domaines-metiers/domaine-metier-list.vue';
import DomaineMetierForm from './general-page/domaines-metiers/domaine-metier-form.vue';
import PaysForm from './general-page/pays/pays-form.vue';
import PaysList from './general-page/pays/pays-list.vue';
import NaturePrestationList from './general-page/nature-prestation/nature-prestation-list.vue';
import NaturePrestationForm from './general-page/nature-prestation/nature-prestation-form.vue';
import TarifForm from './general-page/tarifs/tarif-form.vue';
import TarifList from './general-page/tarifs/tarif-list.vue';

export default {
  components: {
    CategorieList,
    PaysList,
    FormItem,
    ConfirmDeleteItem,
    DomaineMetierList,
    DomaineMetierForm,
    PaysForm,
    NaturePrestationList,
    NaturePrestationForm,
    TarifList,
    TarifForm,
  },
  data: () => ({
    confirmDeletionIsVisible: false,
    formIsVisible: false,
    formComponent: null,
    listComponent: null,
    item: {},
  }),
  mounted() {
    // TODO: Move on change,
    this.initialization();
  },
  computed: {
    items() {
      return this.$store.getters[
        `${this.$route.meta.namespace}/getAllEntities`
      ];
    },
    isSubmitting() {
      return this.$store.getters[
        `${this.$route.meta.namespace}/getIsSubmitting`
      ];
    },
    isDeleting() {
      return this.$store.getters[`${this.$route.meta.namespace}/getIsDeleting`];
    },
    extras() {
      return this.$route.meta.extras.reduce(
        (h, k) => ({ ...h, [k]: this.$store.getters[`${k}/getAllEntities`] }),
        {}
      );
    },
  },
  methods: {
    initialization() {
      const { components, item, fnFetchName, dependsOn } = this.$route.meta;
      this.item = item;

      this[fnFetchName]?.call();
      dependsOn?.forEach((fn) => {
        this[fn].call();
      });

      if (components) {
        const { formComponent, listComponent } = components;
        this.formComponent = formComponent;
        this.listComponent = listComponent;
      }
    },
    fetchAllCategories() {
      this.$store.dispatch(`categories/fetchAll`);
    },

    fetchAllDomainesMetiers() {
      this.$store.dispatch(`domainesMetiers/fetchAll`, {
        queryParams: { limit: 500, sort: 'name,asc' },
      });
    },

    fetchAllPays() {
      this.$store.dispatch(`pays/fetchAll`, {
        queryParams: { limit: 500, sort: 'name,asc' },
      });
    },

    fetchAllNaturePrestation() {
      this.$store.dispatch(`naturePrestation/fetchAll`, {
        queryParams: { limit: 500, sort: 'libelle,asc' },
      });
    },

    fetchAllTarifs() {
      this.$store.dispatch(`tarifs/fetchAll`);
    },

    handleEditDialog(event) {
      this.formIsVisible = true;
      if (event !== undefined && event.id) {
        this.item = event;
      } else {
        this.item = this.$route.meta.item;
      }
    },

    handleEdit(data) {
      this.$store.dispatch(`${this.$route.meta.namespace}/createOrUpdate`, {
        data,
      });
    },

    handleDelete(data) {
      this.confirmDeletionIsVisible = true;
      this.item = data;
    },

    handleConfirmDeletion(id) {
      this.$store.dispatch(`${this.$route.meta.namespace}/delete`, { id });
      this.hideDialogs();
    },

    hideDialogs() {
      this.confirmDeletionIsVisible = false;
      this.formIsVisible = false;
    },
  },
  watch: {
    isSubmitting: function (newValue, oldValue) {
      if (newValue === SUBMIT_SUCCEED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'success',
          summary: 'Accomplie',
          detail: 'Les modifications sur la catégorie ont bien été effectués.',
          life: 7000,
        });
        this.hideDialogs();
      }

      if (newValue === SUBMIT_FAILED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail:
            'Une erreur est survenue lors de la modification sur la catégorie.',
          life: 7000,
        });
      }
    },
    '$route.meta.namespace': {
      handler: function () {
        this.initialization();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>