<template>
  <DataTable ref="categorie-list" data-key="id" :value="items" :paginator="true" :rows="10" :rowsPerPageOptions="[5, 10, 25, 100]" currentPageReportTemplate="Page {first}, {totalRecords} categories.">
    <template #header>
      <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center">
        <h5 class="p-mb-2 p-m-md-0">Gestion des tarifs</h5>
        <!--<Button label="Ajouter un domaine metier" class="p-mr-2 p-mb-2" @click="onCreate" />-->
      </div>
    </template>

    <Column field="id" header="Id" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">#</span>
        {{ slotProps.data.id }}
      </template>
    </Column>
    <Column field="adherant" header="Adherant" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Adhérant</span>
        {{ slotProps.data.adherant }}
      </template>
    </Column>
    <Column field="rang" header="Rang" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Rang</span>
        {{ slotProps.data.rang }}
      </template>
    </Column>
    <Column field="montant_xof" header="Montant (CFA)" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Montant (CFA)</span>
        {{ slotProps.data.montant_xof }}
      </template>
    </Column>
    <!--<Column field="montant_dollar_us" header="Montant (DOLLAR US)" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Montant (DOLLAR US)</span>
        {{ slotProps.data.montant_dollar_us }}
      </template>
    </Column>-->

    <Column field="action" header="Actions" :sortable="true" bodyStyle="text-indent:1em">
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        <Button class="p-button-outlined p-button-rounded p-button-info p-mr-2" @click="onEdit(slotProps.data)" v-tooltip.top="'Modifier'" icon="pi pi-pencil" />
        <!--<Button class="p-button-outlined p-button-rounded p-button-danger p-mr-2" @click="onDelete(slotProps.data)" v-tooltip.top="'Supprimer'" icon="pi pi-trash" />-->
      </template>
    </Column>
  </DataTable>
</template>

<script>
export default {
  props: {
    items: Array,
  },

  methods: {
    onCreate() {
      this.$emit('created');
    },

    onEdit(item) {
      this.$emit('edited', item);
    },

    onDelete(item) {
      this.$emit('deleted', item);
    },
  },
};
</script>