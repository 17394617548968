<template>
  <Dialog
    :visible="isVisible"
    :modal="true"
    @update:visible="onHideDialogs"
    class="p-fluid"
  >
    <template #header>
      <h4>{{ item.id ? 'Modifier' : 'Créer' }} une catégorie</h4>
    </template>
    <p>
      <b
        ><u>NB:</u> Les champs dont le libellé se termine par
        <span class="p-text-bold text-red">*</span> sont obligatoires.</b
      >
    </p>
    <div class="p-field p-col-12">
      <label for="name-id"
        >Libellé <span class="p-text-bold text-red">*</span></label
      >
      <InputText
        v-model="v$.category.name.$model"
        :class="{ 'p-invalid': v$.category.name.$invalid && isSubmitted }"
        id="name-id"
        type="text"
      />
      <small v-if="v$.category.name.$invalid && isSubmitted" class="p-error">
        Le libellé de la categorie est obligatoire.
      </small>
    </div>
    <div class="p-field p-col-12">
      <label for="libelle_en-id">Libellé (EN)</label>
      <InputText
        v-model="v$.category.libelle_en.$model"
        :class="{ 'p-invalid': v$.category.libelle_en.$invalid && isSubmitted }"
        id="libelle_en-id"
        type="text"
      />
    </div>
    <div class="p-field p-col-12">
      <label for="code-id"
        >Code <span class="p-text-bold text-red">*</span></label
      >
      <InputText
        v-model="v$.category.code.$model"
        :class="{ 'p-invalid': v$.category.code.$invalid && isSubmitted }"
        id="code-id"
        type="text"
      />
      <small v-if="v$.category.code.$invalid && isSubmitted" class="p-error">
        Le code de la categorie est obligatoire et doit être unique.
      </small>
    </div>
    <template #footer>
      <Button
        label="Annuler"
        class="p-button p-component p-button-outlined p-button-danger"
        @click="onHideDialogs"
      />
      <Button
        label="Enregistrer"
        class="p-button p-component p-button-outlined p-button-success"
        :loading="isSubmitting === 'SUBMIT_REQUESTED'"
        @click="onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script>
import { required } from '@vee-validate/rules';
import { useVuelidate } from '@vuelidate/core';
import { codeValidatorWrapper } from './utils';

export default {
  setup: () => ({ v$: useVuelidate() }),

  mounted() {
    if (this.item)
      this.category = {
        id: this.item.id,
        name: this.item.name,
        libelle_en: this.item.libelle_en,
        code: this.item.code,
      };
  },

  data: () => ({
    isSubmitted: false,
    category: {
      name: null,
      libelle_en: null,
      code: null,
    },
  }),

  props: {
    item: Object,
    isVisible: Boolean,
    isSubmitting: String,
  },

  validations() {
    return {
      category: {
        name: {
          required,
        },
        libelle_en: {},
        code: {
          required,
          codeValidatorWrapper: codeValidatorWrapper('categories', this.item),
        },
      },
    };
  },

  methods: {
    onHideDialogs() {
      this.$emit('closed');
    },
    onSubmit() {
      this.isSubmitted = true;
      if (this.v$.category.$invalid === false)
        this.$emit('submitted', this.category);
    },
  },

  watch: {
    isSubmitting: function (value) {
      if (value === 'SUBMIT_SUCCEED') {
        this.category = {
          name: null,
          libelle_en: null,
          code: null,
        };
        this.isSubmitted = false;
      }
    },
    item: function (value) {
      if (undefined !== value.id) {
        this.category = {
          id: value.id,
          name: value.name,
          libelle_en: value.libelle_en,
          code: value.code,
        };
      }
    },
  },
};
</script>

<style scoped>
.text-red {
  color: #db3c30;
}
</style>